<template>
  <LiefengContent>
    <template v-slot:title>党员档案
      <span class="eye" @click.stop="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-slot:toolsbarRight>
      <Form :model="searchData" label-position="left" inline  :label-colon="true">
       <FormItem label="姓名" :label-width="45">
         <Input v-model="searchData.name" style="width: 120px"></Input>
       </FormItem>
       <FormItem label="所属社区" :label-width="75">
            <Tooltip placement="right-start"  style="width:100%">
        <div slot="content" style="width: 200px;white-space: normal">
          由于社区太多，请输入关键字进行刷选
        </div>
               <Select v-model="searchData.communityCode" style="width: 120px" filterable :remote-method="loadingCommunityFn" :loading="loadingCommunity">
                  <Option :value="item.value" v-for="(item,index) in communityList" :key="index">{{item.label}}</Option>
               </Select>
          </Tooltip>
             </FormItem>
       <Button type="primary" @click="search" icon="ios-search" style="margin-right: 10px">查询</Button>
        <Dropdown trigger="custom" :visible="visible" style="margin-right: 10px">
        <Button type="success" @click="visible=true">查询更多 <Icon type="ios-arrow-down"></Icon></Button>
        <DropdownMenu slot="list">
           <Form :model="searchData" inline :label-colon="true" :label-width="100">
             <FormItem label="党员编号">
               <Input v-model="searchData.userCode"></Input>
             </FormItem>
             <FormItem label="姓名">
               <Input v-model="searchData.name"></Input>
             </FormItem>
             <FormItem label="电话号码">
               <Input v-model="searchData.account"></Input>
             </FormItem>
             <FormItem label="职业分类">
               <Select v-model="searchData.skill">
                  <Option
                    :value="item.value"
                     v-for="(item,index) in skillList"
                      :key="index"
                  >{{ item.label }}</Option>
               </Select>
             </FormItem>
             <FormItem label="党员服务队">
               <Select v-model="searchData.joinService">
                  <Option value="1">是</Option>
                  <Option value="2">否</Option>
               </Select>
             </FormItem>
             <FormItem label="所属社区">
                <Tooltip max-width="280" placement="top" content="由于社区太多，请输入关键字进行刷选">
               <Select v-model="searchData.communityCode" filterable :remote-method="loadingCommunityFn" :loading="loadingCommunity">
                  <Option :value="item.value" v-for="(item,index) in communityList" :key="index">{{item.label}}</Option>
               </Select>
                </Tooltip>
             </FormItem>
           </Form>
           <div style="text-align: center;margin:10px;">
              <Button type="primary" @click="visible = false;" style="margin-right: 20px">取消</Button>
                <Button type="primary" @click="search">查询</Button>
            </div>
        </DropdownMenu>
    </Dropdown>
      <Button type="error" @click="reset"  icon="ios-refresh" style="margin-right: 10px">重置</Button>
      <Button type="info" @click="toAddParty" icon="ios-add" style="margin-right: 10px">新增党员</Button>
      <Button type="primary" style="margin-right: 10px" @click="toActiveStatistic"
        >党员活动情况统计</Button
      >
      <!-- <Button type="success" style="margin-right: 10px" @click="importExcel" icon="ios-cloud-upload-outline">导入</Button> -->
      <Button type="warning" style="margin-right: 10px" @click="exportExcel" icon="ios-cloud-download-outline">导出</Button>
      <Button type="error" @click="deleteTable" icon="ios-trash-outline">批量删除</Button>
      </Form>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="pageChange"
        :loading="loading"
        :pagesizeOpts="[20, 30, 50, 100]"
        :fixTable="true"
        :pageSize="pageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>
      <!-- 查看 -->
      <LiefengModal
        title="党员详情"
        :value="seeModal"
        @input="seeModalFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea v-if="seeModal">
          <SeeParty :partyInfo="partyId"></SeeParty>
        </template>
      </LiefengModal>
      <!-- 新增/修改弹窗 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeModal"
        @input="addChangeModalFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <AddChangeParty
            ref="addChangeParty"
            @pageChange="pageChange"
            @addChangeModalFn="addChangeModalFn"
            @selectMenu="selectMenu"
            :addChangeSign="addChangeSign"
            :partyId="partyId"
            v-if="addChangeModal"
          ></AddChangeParty>
        </template>
        <template v-slot:toolsbar>
          <Button type="primary" @click="backStep" :disabled="stepStatus == 0 ? true : false"
            >上一步</Button
          >
          <Button
          type="primary"
            @click="nextStep"
            :disabled="stepStatus == 1 ? true : false"
            style="margin-left: 10px"
            >下一步</Button
          >
          <Button type="warning" @click="submitParty" style="margin-left: 10px">提交</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/partyindex")
import AddChangeParty from "@/views/party/childrens/addChangeParty";
import SeeParty from "@/views/party/childrens/seeParty";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      hasSensitive:true,
      searchData: {},
      visible: false,
      skillList: [],
      communityList: [],
       //所属社区全部数据
      allCommunityList: [],
      loadingCommunity: false,
      // 新增/修改标识  true代表新增
      addChangeSign: true,
      loading: true,
      addChangeTitle: "",
      //表格数据
      tableData: [],
      //表头列表
      talbeColumns: [
        {
          type: "selection",
          width: 70,
          align: "center",
        },
        {
          title: "党员编号",
          key: "userCode",
          width: 140,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 130,
          align: "center",
        },
        {
          title: "电话号码",
          key: "account",
          width: 130,
          align: "center",
        },
        {
          title: "所属社区",
          key: "community",
          align: "center",
          minWidth: 170
        },
        {
          title: "职业",
          key: "skill",
          align: "center",
          width: 150
        },
        {
          title: "能参加的时间",
          key: "serviceTime",
          width: 170,
          align: "center",
        },
        {
          title: "是否加入党员服务队",
          key: "joinService",
          width: 170,
          align: "center",
        },
        {
          title: "入党时间",
          key: "joinDate",
          width: 140,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                    //trigger: "click"
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down"
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.seeParty(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "查看"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.changeParty(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "修改"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.activeParty(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "活动记录"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      //数据总数
      total: 0,
      page: 1,
      pageSize: 20,
      //选中表格数据
      selectTableArr: [],
      partyId: {},
      seeModal: false,
      addChangeModal: false,
      //新增修改弹窗步数
      stepStatus: 0,
    };
  },
  methods: {
     //异步搜索所属社区
    loadingCommunityFn(query) {
      if(query !== '' && !Number(query)) {
        this.loadingCommunity = true;
       this.communityList = this.allCommunityList.filter(item => item.label.indexOf(query) > -1);
       if(this.communityList.length > 50) this.communityList.length = 50;
        this.loadingCommunity = false;
      }else if(Number(query)) {
        return 
      }else {
        this.communityList = []
      }
    },
    selectMenu(stepStatus) {
      this.stepStatus = stepStatus;
    },
    //   上下一步/提交
    backStep() {
      this.stepStatus = this.$refs.addChangeParty.nextstep(-1);
    },
    nextStep() {
      this.stepStatus = this.$refs.addChangeParty.nextstep(1);
    },
    submitParty() {
      this.$refs.addChangeParty.submit();
    },
    //导入excel
    importExcel() {
      this.$Message.error("敬请期待")
    },
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
           "党员编号",
          "姓名",
          "电话号码",
          "所属社区",
          "职业",
          "能参加的时间",
          "是否加入党员队列",
          "入党时间",
          ];
          let filterVal = [
            "userCode",
          "name",
          "account",
          "community",
          "skill",
          "serviceTime",
          "joinService",
          "joinDate",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "党员档案"
          );
        },
      });
    },
    //党员活动情况统计
    toActiveStatistic() {
      this.$router.push("/partystatistic");
    },
    //选中表格某项
    tableSelect(arr) {
      this.selectTableArr = [];
      arr.map((item, index) => {
        this.selectTableArr.push(item.id);
      });
    },
    //删除
    deleteTable() {
      if(this.selectTableArr.length == 0) {
        this.$Message.error({
          background: true,
          content: "请选择需要删除的党员信息"
        })
        return ;
      }
       this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>确定删除党员信息？</p>",
        onOk: () => {
           this.$post("/syaa/pc/sy/user/communist/deleteByUserId", {
        ids: this.selectTableArr.join(","),
      })
        .then((res) => {
          if (res.code == 200) {
            this.pageChange({
              page: this.page,
              pageSize: this.pageSize,
            });
            this.$Message.success({
              background: true,
              content: "删除成功！",
            });
          } else {
            this.$Message.error({
              background: true,
              content: "删除失败！",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
        }
       })
    },
    //查看操作
    seeParty(value) {
      this.partyId = {
        id: value.id,
        userId: value.userId,
      };
      this.seeModal = true;
    },
    seeModalFn(status) {
      this.seeModal = status;
    },
    addChangeModalFn(status) {
      this.addChangeModal = status;
    },
    changeParty(value) {
      this.partyId = {
        id: value.id,
        userId: value.userId,
      };
      this.stepStatus = 0;
      this.addChangeTitle = "修改党员";
      this.addChangeSign = false;
      this.addChangeModal = true;
    },
    //党员活动记录
    activeParty(value) {
      this.$router.push({
        path: "/partyrecord",
        query: {
          custGlobalId: value.custGlobalId,
        },
      });
    },
    toAddParty() {
        this.stepStatus = 0;
      this.addChangeTitle = "新增党员";
      this.addChangeSign = true;
      this.addChangeModal = true;
    },
    search() {
      this.pageChange({
        page: 1,
        pageSize: this.pageSize
      })
    },
    reset() {
      this.searchData = {
        communityCode: this.communityList[0] ? this.communityList[0].value : ''
      };
      this.search();
    },
    //分页改变
    pageChange(obj) {
      this.loading = true;
      this.$get("/syaa/pc/sy/user/communist/selectPage", {
        ...this.searchData,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize,
        hasSensitive:this.hasSensitive
      }).then(
        (res) => {
          if (res.code == 200) {
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
            this.page = res.currentPage;
            //能参加社区服务的时间
            //能参加的时间
            this.$get("/datamsg/api/common/sys/findDictByDictType", {
              dictType: "COMMUNSIT_SERVICE_TIME",
            }).then((res1) => {
              if (res1.code == 200) {
                res.dataList.map((item, index) => {
                  res.dataList[index].joinService =
                    item.joinService == 1 ? "是" : "否";
                  res1.dataList.map((cur) => {
                    if (cur.dictKey == item.serviceTime) {
                      res.dataList[index].serviceTime = cur.dictValue;
                    }
                  });
                });
              
              res.dataList.map(item => {
                  this.skillList.map(subItem => {
                    if(item.skill == subItem.value) {
                      item.skill = subItem.label;
                    }
                  })
              })
                this.tableData = res.dataList;
                this.loading = false;
              }else {
                   this.$Message.error({
                        background: true,
                    content: "获取数据失败",
                    });
                  this.loading = false;
              }
            });
          } else {
            this.$Message.error({
              background: true,
              content: "获取数据失败",
            });
            this.loading = false;
          }
        }
      ).catch(err => {
          console.log(err);
      })
    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.pageChange({
        page: this.page,
        pageSize: this.pageSize
      })
    },
  },
  async created() {
    //获取职业分类列表
   await this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNIST_SKILL",
    }).then((res) => {
      if (res.code == 200) {
        this.skillList = res.dataList.map(item => {
          return {
            value: item.dictKey,
            label: item.dictValue
          }
        })
      }
    });
    // 获取社区
    await this.$get("/gx/pc/organization/selectDatsScopeCommunity", {
      custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      oemCode: parent.vue.oemInfo.oemCode,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then((res) => {
      if (res.code == 200) {
          this.allCommunityList = res.dataList.map(item => {
          return {
            value: item.orgCode,
            label: item.orgName
          }
        }); 
        res.dataList.length = res.dataList.length > 50 ? 50 : res.dataList.length;
        this.communityList = res.dataList.map(item => {
          return {
            value: item.orgCode,
            label: item.orgName
          }
        });
        this.searchData.communityCode = this.communityList[0] ? this.communityList[0].value : '';
      }
    });
    this.pageChange({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    SeeParty,
    AddChangeParty,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-form-item {
  margin-bottom: 0;
}
/deep/#toolsbarRight {
  width: 1000%;
}
/deep/.ivu-select-dropdown {
  left: 0;
  width: 100%;
  .ivu-dropdown-menu {
    padding: 20px;
    .ivu-form {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .ivu-form-item {
        width: 45%;
        margin-bottom: 20px;
        .ivu-form-item-label {
          text-align: right;
        }
      }
    }
  }
}
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>